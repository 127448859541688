import React, { useState,useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./CountryDropDown.scss";
import { IoMdClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

const CountryDropdown = ({
  selectedCountry,
  placeholder,
  heightFor,
  paddingFor,
  heightForTwo,
  marginFor,
  locationFromAPI
}) => {

  console.log(selectedCountry)
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  
  const useStyle = {
    height: heightFor,
  };
  const useStyleTwo = {
    padding: paddingFor,
  };
  const useStyleThree = {
    height: heightForTwo,
    marginTop: marginFor,
  };


  const africaData = {
    name: "Africa",
    selected: false,
    regions: [
      {
        name: "Northern Africa",
        selected: false,
        countries: [
          { name: "Algeria", selected: false },
          { name: "Egypt", selected: false },
          { name: "Libya", selected: false },
          { name: "Morocco", selected: false },
          { name: "Sudan", selected: false },
          { name: "Tunisia", selected: false },
          { name: "Western Sahara", selected: false },
        ],
      },
      {
        name: "Southern Africa",
        selected: false,
        countries: [
          { name: "Botswana", selected: false },
          { name: "Eswatini (Swaziland)", selected: false },
          { name: "Lesotho", selected: false },
          { name: "Namibia", selected: false },
          { name: "South Africa", selected: false },
          { name: "Zimbabwe", selected: false },
        ],
      },
      {
        name: "Central Africa",
        selected: false,
        countries: [
          { name: "Angola", selected: false },
          { name: "Cameroon", selected: false },
          { name: "Central African Republic", selected: false },
          { name: "Chad", selected: false },
          { name: "Congo (Brazzaville)", selected: false },
          { name: "Congo (Kinshasa)", selected: false },
          { name: "Equatorial Guinea", selected: false },
          { name: "Gabon", selected: false },
          { name: "São Tomé and Príncipe", selected: false },
        ],
      },
      {
        name: "Western Africa",
        selected: false,
        countries: [
          { name: "Benin", selected: false },
          { name: "Burkina Faso", selected: false },
          { name: "Cape Verde", selected: false },
          { name: "Côte d'Ivoire", selected: false },
          { name: "Gambia", selected: false },
          { name: "Ghana", selected: false },
          { name: "Guinea", selected: false },
          { name: "Guinea-Bissau", selected: false },
          { name: "Liberia", selected: false },
          { name: "Mali", selected: false },
          { name: "Mauritania", selected: false },
          { name: "Niger", selected: false },
          { name: "Nigeria", selected: false },
          { name: "Senegal", selected: false },
          { name: "Sierra Leone", selected: false },
          { name: "Togo", selected: false },
        ],
      },
      {
        name: "Eastern Africa",
        selected: false,
        countries: [
          { name: "Burundi", selected: false },
          { name: "Comoros", selected: false },
          { name: "Djibouti", selected: false },
          { name: "Eritrea", selected: false },
          { name: "Ethiopia", selected: false },
          { name: "Kenya", selected: false },
          { name: "Madagascar", selected: false },
          { name: "Malawi", selected: false },
          { name: "Mauritius", selected: false },
          { name: "Mozambique", selected: false },
          { name: "Rwanda", selected: false },
          { name: "Seychelles", selected: false },
          { name: "Somalia", selected: false },
          { name: "South Sudan", selected: false },
          { name: "Tanzania", selected: false },
          { name: "Uganda", selected: false },
          { name: "Zambia", selected: false },
        ],
      },
    ],
  };
  
  const [data, setData] = useState(africaData);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = {
    ...data,
    regions: data.regions
      .filter(
        (region) =>
          region.name.toLowerCase().includes(searchTerm) ||
          region.countries.some((country) =>
            country.name.toLowerCase().includes(searchTerm)
          )
      )
      .map((region) => ({
        ...region,
        countries: region.countries.filter((country) =>
          country.name.toLowerCase().includes(searchTerm)
        ),
      })),
  };

  const handleSelectAfrica = () => {
    const updatedData = { ...data };
    const newStatus = !updatedData.selected;

    updatedData.selected = newStatus;

    updatedData.regions.forEach((region) => {
      region.selected = newStatus;
      region.countries.forEach((country) => (country.selected = newStatus));
    });

    setData(updatedData);
    updateParentSelection(updatedData);
  };

  const handleSelectRegion = (regionName) => {
    const updatedData = { ...data };
    const region = updatedData.regions.find((r) => r.name === regionName);
    if (!region) return;

    const newStatus = !region.selected;
    region.selected = newStatus;

    region.countries.forEach((c) => (c.selected = newStatus));

    // If all regions are selected, mark Africa as selected
    updatedData.selected = updatedData.regions.every((r) => r.selected);

    setData(updatedData);
    updateParentSelection(updatedData);
  };


  

  const handleSelectCountry = (regionName, countryName) => {
    const updatedData = { ...data };
    const region = updatedData.regions.find((r) => r.name === regionName);
    if (!region) return;

    const country = region.countries.find((c) => c.name === countryName);
    if (!country) return;

    country.selected = !country.selected;

    // If all countries in a region are selected, mark the region as selected
    region.selected = region.countries.every((c) => c.selected);

    // If all regions are selected, mark Africa as selected
    updatedData.selected = updatedData.regions.every((r) => r.selected);

    setData(updatedData);
        updateParentSelection(updatedData);
    // updateParentSelection(getSelectedTextNoFilter(updatedData));
  };

  const getSelectedText = () => {
    // If all of Africa is selected, return "Africa"
    if (data.selected) {
      return "Africa";
    }
  
    const selectedRegions = data.regions.filter((r) => r.selected);
    const selectedCountries = data.regions.flatMap((r) =>
      r.countries.filter((c) => c.selected)
    );
  
    // If only regions are selected
    if (selectedRegions.length > 0) {
      if (selectedRegions.length === 1) {
        return selectedRegions[0].name;
      }
      return `${selectedRegions.length} regions`;
    }
  
    // // If regions and countries are selected, return regions only
    // if (selectedRegions.length > 0 && selectedCountries.length > 0) {
    //   return selectedRegions.map((r) => r.name).join(", ");
    // }
  
    // If only countries are selected
    if (selectedCountries.length > 0) {
      if (selectedCountries.length === 1) {
        return selectedCountries[0].name;
      }
      return `${selectedCountries.length} countries`;
    }
  
    // Default placeholder text
    return "";
  };


  // const getSelectedTextNoFilter = () => {
  //   // If all of Africa is selected, return Africa
  //   if (data.selected) {
  //     return { continent: "Africa", regions: [], countries: [] };
  //   }
  
  //   // Get all selected regions
  //   const selectedRegions = data.regions
  //     .filter((r) => r.selected)
  //     .map((r) => r.name);
  
  //   // Get all selected countries
  //   const selectedCountries = data.regions.flatMap((r) =>
  //     r.countries.filter((c) => c.selected).map((c) => c.name)
  //   );
  
  //   // Return a structured object
  //   return {
  //     continent: "Africa",
  //     regions: selectedRegions,
  //     countries: selectedCountries,
  //   };
  // };

  const clearCountry = () => {
    setData({
      ...data,
      selected: false,
      regions: data.regions.map((region) => ({
        ...region,
        selected: false,
        countries: region.countries.map((country) => ({
          ...country,
          selected: false,
        })),
      })),
    });
  };

  // Prepopulate selections from API response
  useEffect(() => {
    if (locationFromAPI && locationFromAPI.length > 0) {
      const updatedData = { ...africaData };

      // Select countries received from API
      updatedData.regions.forEach((region) => {
        region.countries.forEach((country) => {
          if (locationFromAPI.includes(country.name)) {
            country.selected = true;
          }
        });

        // If all countries in a region are selected, select the region
        region.selected = region.countries.every((c) => c.selected);
      });

      // If all regions are selected, mark Africa as selected
      updatedData.selected = updatedData.regions.every((r) => r.selected);

      setData(updatedData);
      updateParentSelection(updatedData)
    }


     // eslint-disable-next-line
  }, [locationFromAPI]);

    // Function to update selection state and notify parent
    const updateParentSelection = (updatedData) => {
      const selectedCountries = updatedData.regions.flatMap((r) =>
        r.countries.filter((c) => c.selected).map((c) => c.name)
      );
  
      const selectedRegions = updatedData.regions
        .filter((r) => r.selected)
        .map((r) => r.name);
  
      const selectionPayload = {
        continent: updatedData.selected ? "Africa" : null,
        regions: selectedRegions,
        countries: selectedCountries,
      };
  
      selectedCountry(selectionPayload);
    };
  
  

  return (
    <div style={useStyle} className="custom_select_container">
      <div
        style={useStyleTwo}
        className="custom_selected_option"
        onClick={toggleDropdown}
      >
        {getSelectedText() === ""  ? (
            <p className="custom_selected_placeholder">{placeholder}</p>
        ):(
           <div className="custom_selected_location">
          
           <p>{getSelectedText()}</p>
              <IoMdClose
                 color='#007EFF'
                 size={18}
                 onClick={()=>clearCountry()}
               />
           </div>
        )}

        {isOpen ? (
          <IoIosArrowUp style={{ fontSize: "20px", color: "#B8B3A7" }} />
        ) : (
          <IoIosArrowDown style={{ fontSize: "20px", color: "#B8B3A7" }} />
        )}
      </div>
      {isOpen && (
        <>
          
       
        <ul style={useStyleThree} className="custom_country_cont">

        <div className="custom_search_input">
            <FiSearch
            color="#9CA0A5"
            size={20}
            />
            <input
                type="text"
                placeholder="Search regions or country"
                value={searchTerm}
                onChange={handleSearch}
                
              />
              <IoClose
               color="#9CA0A5"
               size={20}
              />
        </div>

          <div className="custom_country_select">
            <input
              type="checkbox"
              checked={data.selected}
              onChange={handleSelectAfrica}
            />
            <h4>{data.name}</h4>
          </div>
          <hr />
          <h3>Regions</h3>
          {filteredData.regions.map((region) => (
              <div key={region.name} className="custom_country_select">
                <input
                  type="checkbox"
                  checked={region.selected}
                  onChange={() => handleSelectRegion(region.name)}
                />
                <h4>{region.name}</h4>
              </div>
            ))}
          <hr />
          <h3>Countries</h3>
          {filteredData.regions.map((region) => (
              <div key={region.name}>
                {region.countries.map((country) => (
                  <div key={country.name} className="custom_country_select">
                    <input
                      type="checkbox"
                      checked={country.selected}
                      onChange={() =>
                        handleSelectCountry(region.name, country.name)
                      }
                    />
                    <h4>{country.name}</h4>
                  </div>
                ))}
            </div>
          ))}
        </ul>
        </>
      )}
    </div>
  );
};

export default CountryDropdown;
