import React,{useState,useEffect} from 'react';
import './PdfViewer.scss';
import report from '../../../../assets/docGold.png';
import { GoDotFill } from "react-icons/go";
// import { AiOutlineLink } from "react-icons/ai";
// import { RxDownload } from "react-icons/rx";
// import { FiBookmark } from "react-icons/fi";
import shakeImage from '../../../../assets/High-Five.png'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../DashboardLoader/DashboardLoader';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from '../../../../assets/greys.png';
import BaseButton from '../../../Onboarding/Reusables/BaseButton/BaseButton';
import { IoWarningOutline } from "react-icons/io5";
import { GoQuestion } from "react-icons/go";
import ReportContentView from '../ReportContentView/ReportContentView';
import { useNavigate } from 'react-router-dom';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 448,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
   
  };

  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
  }


  const styleThree = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
   
  };

const PdfViewer = () => {

   
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const paramData = useParams()
    const [docData,setDocData] = useState([]);
   const [loading, setLoading] = useState(true);
   const [open, setOpen] = useState(false); 
   const [openTwo, setOpenTwo] = useState(false); 
   const [openThree, setOpenThree] = useState(false); 
   const [openFour, setOpenFour] = useState(false); 
   const [openSucess,setOpenSuccess]= useState(false)
   const [rejection_reason, setRejectionReason] = useState('');
   const [suspension_reason, setSuspensionReason] = useState('');
   const viewOnlyUrl = `${docData.url}#toolbar=0&view=FitH`;
   const [rejectLoading, setRejectLoading] = useState(false);
   const [suspendLoading, setSuspendLoading] = useState(false);
   const [reasonHeader,setReasonHeader] = useState('');
   const [withdrawReason,setWithdrawReason]= useState("");
    const [withdrawLoad,setWithdrawLoad]= useState(false);
   const [reasonText,setReasonText] = useState('');
   const [docAction,setDocAction] = useState('');
   const navigate = useNavigate();

    const getDocuments = () => {

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
            };
        
          axios.get(`${baseUrl}documents/contributor/${paramData.id}`, config)
            .then((response) => {
              
              console.log(response.data);
             setDocData(response.data.data)
                setLoading(false);
            
            })
            .catch((error) => {
           
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };


    //Reject document
    const rejectDocument = () => {

        if(rejection_reason === ""){
            alert("Please provide a reason for rejection")
            return
        }

        let body={
            rejection_reason:rejection_reason
        }

        try{ 
      
            setRejectLoading(true)
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${paramData.id}/reject`, body, config)
            .then((response) => {
              
              console.log(response.data);
              setRejectLoading(false)
              setOpen(false);
              setDocAction("Rejected")
              setOpenSuccess(true)
              getDocuments()
            
            })
            .catch((error) => {
                setRejectLoading(false)
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };

    //Approve document
    const approveDocument = () => {
   

        try{ 
      
            
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                   Authorization: `Bearer ${token}`,
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${paramData.id}/merge`,{}, config)
            .then((response) => {
              
              console.log(response.data);
            
              setDocAction("Approved")
              setOpenSuccess(true)
              alert(response.data.message)
              getDocuments()
            
            })
            .catch((error) => {
           
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };

    // reactivate doc
    const reactivateDocument = () => {
   

        try{ 
      
            
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                   Authorization: `Bearer ${token}`,
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${paramData.id}/reactivate`,{}, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              getDocuments()
            
            })
            .catch((error) => {
           
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };

    //Sudspend document
    const suspendDocument = () => {
   
        if(suspension_reason === ""){
            alert("Please provide a reason for suspension")
            return
        }
        let body={
            "suspension_reason":suspension_reason
        }
        try{ 
         setSuspendLoading(true)
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${paramData.id}/suspend`,body, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message);
              setOpenTwo(false);
              setDocAction("Suspended")
              setOpenSuccess(true)
              setSuspendLoading(false)
              getDocuments()
            
            })
            .catch((error) => {
                setSuspendLoading(false)
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };

    const withdrawDocument = () => {

        if(withdrawReason === ""){
            alert("Please enter a reason for withdrawing the document");
            return
        }

        try{ 
      
          setWithdrawLoad(true)

          let body = {
            "withdrawal_reason":withdrawReason
          }
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${docData.id}/withdraw`,body, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              setOpenThree(false);
              setDocAction("Withdrawn")
              setOpenSuccess(true)
              setWithdrawLoad(false)
              getDocuments()
             
            })
            .catch((error) => {
              setWithdrawLoad(false)
                if (error.response) {
                    if (error.response.data.message === "Please login again.") {
                        localStorage.clear(); // Clear local storage
                        window.location.href = '/login'; // Redirect to login page
                    } else {
                        alert(error.response.data.message);
                    }
                } else if (error.request) {
                    alert("Check your connection. Unable to reach the server.");
                } else {
                    alert("An error occurred. Please try again later.");
                }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };

    const resubmitDoc = () => {

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Authorization header
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${docData.id}/resubmit`,{}, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              getDocuments()
             
            })
            .catch((error) => {
              
                if (error.response) {
                    if (error.response.data.message === "Please login again.") {
                        localStorage.clear(); // Clear local storage
                        window.location.href = '/login'; // Redirect to login page
                    } else {
                        alert(error.response.data.message);
                    }
                } else if (error.request) {
                    alert("Check your connection. Unable to reach the server.");
                } else {
                    alert("An error occurred. Please try again later.");
                }
            });
          }
          catch(error){
            if (error.response) {
                alert(error.response.data.message);
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
               alert("An error occurred. Please try again later.");
            }
          }
    };

    
      
      
        useEffect(()=>{   
            getDocuments()
        // eslint-disable-next-line
    },[]);

    function formatDateLong(isoString) {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const handleRejectionReasonChange = (e) => {
        const words = e.target.value.split(/\s+/);
        if (words.length <= 75) {
            setRejectionReason(e.target.value);
        }
    };

    const handleSuspensionChange = (e) => {
        const words = e.target.value.split(/\s+/);
        if (words.length <= 75) {
            setSuspensionReason(e.target.value);
        }
    };


    function handleRejectReason(){
        setReasonHeader("Rejection")
        setReasonText(docData.rejection_reason)
        setOpenFour(true)
    }
    

    function handleWdrawReason(){
        setReasonHeader("Withdrawal")
        setReasonText(docData.withdrawal_reason)
        setOpenFour(true)
    }
   
    function handleSusReason(){
        setReasonHeader("Suspension")
        setReasonText(docData.suspension_reason)
        setOpenFour(true)
    }
   
    return (
        
        loading ? <DashboardLoader/> :
        <>
        <div className='pdf-backgd'>

            <div className='pdf-main-cont'>

                <div className='pdf-cont-header'>

                    <div className='pdf-header-left'>

                        <div>
                         <img src={report} alt="" />
                        </div>

                        <div>
                            <h4 style={{width:"80%"}}>{docData.title}</h4>
                            <div className='pdf-left-tag'>
                                <div className='pdf-left-tag-views'>
                                    <GoDotFill
                                    color='#04802E'
                                    />
                                    <h3> {docData.total_views} Views</h3>
                                </div>
                               
                               
                                <p
                                    className='admin-col-status'
                                    style={{
                                    color:
                                        docData.status === "rejected"
                                        ? "#9E0A05"
                                        : docData.status === "pending_review"
                                        ? "#8B5904"
                                        : docData.status === "withdrawn"
                                        ? "#4B4F57"
                                        : docData.status === "suspended"
                                        ? "#9E0A05"
                                        : "#036B26",

                                        backgroundColor:
                                        docData.status === "rejected"
                                        ? "#FBEAE9"
                                        : docData.status === "pending_review"
                                        ? "#FEF0D8"
                                        : docData.status === "withdrawn"
                                        ? "#F3F2EF"
                                         : docData.status === "suspended"
                                        ? "#FBEAE9"
                                        : "#E7F6EC",
                                    fontWeight:500
                                    }}
                                    >
                                    {docData.status}
                                </p>

                                <div className='pdf-left-tag-tags'>
                                    <GoDotFill
                                    color='#865503'
                                    />
                                    <h3>{docData.industries.map(industry => industry.name).join(', ')}</h3>
                                </div>
                    

                                <p>Submitted: {formatDateLong(docData.created_at)}</p>
                                {docData.merged_date !== null &&
                                <p>Merged: {formatDateLong(docData.merged_date)}</p>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='pdf-header-btn'>

                        <div >
                            <h4
                            onClick={()=>window.location.href=`/dashboard/contributions`}
                            className='pdf-header-close-btn'
                            >Close </h4>
                        </div>
                                
                        {(docData.status === "merged") && (docData.created_by_account_type !== 'super_admin') &&
                        <div 
                        onClick={()=>setOpenTwo(true)}
                        className='pdf-header-report-btn'>
                            <IoWarningOutline
                                color='#D42620'
                            />
                            <h4>Suspend</h4>
                        </div>
                       }

                        {(docData.status === "merged") && (docData.created_by_account_type === 'super_admin') &&
                        <div 
                        onClick={()=>setOpenThree(true)}
                        className='pdf-header-report-btn'>
                            <IoWarningOutline
                                color='#D42620'
                            />
                            
                            <h4>Withdraw</h4>
                        </div>
                       }

                        {docData.status === "pending_review" &&
                        <>
                        <div
                        onClick={()=>setOpen(true)}
                         className='pdf-header-reject-btn'>
                          
                            <h4
                            >Reject</h4>
                        </div>

                        <div 
                        onClick={()=>approveDocument()}
                        className='pdf-report-btn-green'>
                          
                            <h4>Approve</h4>
                        </div>
                        </>
                      }

                        {docData.status === "rejected" &&
                        
                            <>
                            <div 
                            onClick={()=>approveDocument()}
                            className='pdf-report-btn-green'>
                            
                                <h4>Approve</h4>
                            </div>

                            <div 
                            onClick={()=>handleRejectReason()}
                            className='pdf-header-report-btn'>
                                <GoQuestion
                                    color='#D42620'
                                />
                                
                                <h4>Show reason for rejection</h4>
                            </div>
                            </>
                         }

                        {docData.status === "withdrawn" && (docData.created_by_account_type !== 'super_admin') &&
                        
                           
                            <div 
                            onClick={()=>handleWdrawReason()}
                            className='pdf-header-report-btn'>
                                <GoQuestion
                                    color='#D42620'
                                />
                                
                                <h4>Show reason for withdrawal </h4>
                            </div>
                            
                         }



                        {docData.status === "withdrawn" &&  (docData.created_by_account_type === 'super_admin') &&
                        
                        <>
                        <div 
                        onClick={()=>resubmitDoc()}
                        className='pdf-report-btn-green'>
                          
                            <h4>Resubmit </h4>
                        </div>

                            <div 
                            onClick={()=>handleWdrawReason()}
                            className='pdf-header-report-btn'>
                                <GoQuestion
                                    color='#D42620'
                                />
                                
                                <h4>Show reason for withdrawal </h4>
                            </div>
                            
                            </>
                         }

                     {docData.status === "suspended" &&
                        
                        <>
                        <div 
                        onClick={()=>reactivateDocument()}
                        className='pdf-report-btn-green'>
                          
                            <h4>Reactivate </h4>
                        </div>

                        <div 
                        onClick={()=>handleSusReason()}
                        className='pdf-header-report-btn'>
                            <GoQuestion
                                color='#D42620'
                            />
                            
                            <h4>Show reason for suspension</h4>
                        </div>
                        </>
                                                    
                      }

                    {(docData.created_by_account_type === 'super_admin') &&
                          <BaseButton
                              onClick={()=>navigate(`/admin/editdoc/${docData.id}`)}
                              title='Update Metadata'
                              height='44px'
                              width='auto'
                              padding="0em .5em"
                          />
                       }
                    </div>

                </div>

                <ReportContentView
                    docData={docData}
                 />


                <section className='pdf-section' >

                   

                    <div className='pdf-iframe-cont'>
                    
                    <iframe
                    src={viewOnlyUrl}
                    title="PDF Viewer"
                    style={{ width: '100%', height: '100vh', border: 'none',padding:"40px 0px" }}
                    >

                    </iframe>
                    
                    </div>

                    {/* <div>

                        <div className='pdf-icons'>
                            <AiOutlineLink
                            size={18}
                            />
                        </div>

                        <div className='pdf-icons'>
                            <RxDownload
                            size={18}
                            />
                        </div>

                        <div className='pdf-icons'>
                            <FiBookmark
                            size={18}
                            />
                        </div>

                    </div> */}
                </section>
                
            </div>
     
        </div>
        

      

        {/* REJECTION MODAL */}
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

            <div className='pdf-modal-cont'>
                
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpen(false)} src={closeIcon} alt="" />
                </div>

                <h4>Reason for Rejection</h4>

                <h5>Why is this contribution rejected</h5>

                <textarea 
                name="" 
                id=""
                maxLength={75}
                value={rejection_reason}
                onChange={handleRejectionReasonChange}
                >


                </textarea>
                <p>Please keep this below 75 words.</p>
                
                {rejectLoading ? 
                
                <p style={{textAlign:'center'}}>Please wait...</p> 
                
                :
                <BaseButton
                
                 title={"Confirm Rejection"}
                 width={"100%"}
                 height={"44px"}
                 padding={"0.5em 1em"}
                 onClick={()=>rejectDocument()}
                />
                }
                
                  
               

          
            </div>

        </Box>
        </Modal>

        {/* SUSPENSION MODAL */}
        <Modal
            open={openTwo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

            <div className='pdf-modal-cont'>
                
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpenTwo(false)} src={closeIcon} alt="" />
                </div>

                <h4>Reason for Suspension</h4>

                <h5>Why is this contribution being suspended?</h5>

                <textarea 
                name="" 
                id=""
                maxLength={75}
                value={suspension_reason}
                onChange={handleSuspensionChange}
                >


                </textarea>
                <p>Please keep this below 75 words.</p>

                {suspendLoading ? <p style={{textAlign:'center'}}> Please wait...</p>

                :
                <BaseButton
                
                 title={"Confirm Suspension"}
                 width={"100%"}
                 height={"44px"}
                 padding={"0.5em 1em"}
                 onClick={()=>suspendDocument()}
                />
                }
                
                  
               

          
            </div>

        </Box>
        </Modal>


        {/* WITHDRAWAL MODAL */}
        <Modal
            open={openThree}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={styleTwo} >

        <div className='pdf-withdraw-modal'>
            
        <div className='pdf-withdraw-modal-top'>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpenThree(false)} src={closeIcon} alt="" />
                </div>
                <h4>Withdraw Contribution</h4>

           
                <div className='pdf-withdraw-info'>

                    <h3>Title</h3>
                    <p>{docData.title}</p>
                    <h3>Type</h3>
                    <p>Document</p>
                    

                   
                </div>

                <textarea 
                    className='pdf-withdraw-text'
                    name="" id=""
                    placeholder='Please tell us why you are withdrawing this contribution'
                    value={withdrawReason}
                    onChange={(e) => setWithdrawReason(e.target.value)}
                >

                    </textarea>

                    <p>Withdrawing this contribution will make it unavailable for public viewing. Do you wish to continue?</p>
                    
            </div>


            {withdrawLoad ? 
            
            <p
                style={{textAlign:'center'}}
            >Please wait...</p>
            :
            <div>
                <BaseButton
                    title={"Confirm Withdrawal"}
                    width={"100%"}
                    height={"44px"}
                    padding={"0.5em 1em"}
                    onClick={() => withdrawDocument()}
                />
            </div>
            }
            
        </div>

        </Box>
        </Modal>

        {/* REASON MODAL */}
        
        <Modal
        open={openFour}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

        <div className='pdf-reject-modal' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img onClick={() => setOpenFour(false)} src={closeIcon} alt="" />
                    </div>
                    <h4 style={{textAlign:"center",fontSize:"1.75rem",marginBottom:"10px"}}>Reason for {reasonHeader}</h4>
                    <p>{reasonText}</p>
                </div>

              
                <div>
                    <BaseButton
                        title={"Close"}
                        width={"100%"}
                        height={"44px"}
                        padding={"0.5em 1em"}
                        onClick={() => setOpenFour(false)}
                    />
                </div>
            </div>
        </div>

        </Box>
        </Modal>
       


        {/* SUCCESFUL ACTION */}
        <Modal
            open={openSucess}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={styleThree} >

        <div className='pdf-withdraw-modal'>
            
        <div className='pdf-withdraw-modal-top'>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpenSuccess(false)} src={closeIcon} alt="" />
                </div>
                <h4>Contribution {docAction}</h4>

            <div style={{textAlign:"center",marginBottom:'24px'}}>
            <img src={shakeImage} alt="" />
            </div>
          
                
            <div>
                <BaseButton
                    title={"Close"}
                    width={"100%"}
                    height={"44px"}
                    padding={"0.5em 1em"}
                    onClick={() => setOpenSuccess(false)}
                />
            </div>
            
            
        </div>
        </div>

        </Box>
        </Modal>
        </>
    );
};

export default PdfViewer;
