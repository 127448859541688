import React,{useState,useEffect} from 'react';
import './ContributionHeader.scss'
import { IoSearch } from "react-icons/io5";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from '../../../../assets/greys.png';
import paper1 from '../../../../assets/newspaperTwo.png';
import paper2 from '../../../../assets/newspaperTwoUn.png';
import check from '../../../../assets/Outer Circle.png';
import checked from '../../../../assets/checked.svg';
import { LuArrowUpDown } from "react-icons/lu";
import { BsFilter } from "react-icons/bs";
import { GoChevronDown } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosRadioButtonOff } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import checkIcon from '../../../../assets/checkIcon.svg';
import checkedIcon from '../../../../assets/checkedIcon.svg';
import axios from 'axios';
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { IoLanguage } from "react-icons/io5";
import { FaHashtag } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { GoChevronUp } from "react-icons/go";
import { MdCheckCircle } from "react-icons/md";
import Calendar from '../../Reusable/Calendar/Calendar';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 640,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
   
  };

const ContributionHeader = ({
    onSearchChange,
    onLanguageChange,
    onTagsChange,
    onDateChange,
    onLangRemove,
    onDateRemove,
    onTagsRemove,
    onSortChange,
}) => {

const [selectedItem,setSelectedItem] = useState("doc");
const [open,setOpen] = useState(false);
const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [industries, setIndustries]= useState([])
  const [selectedLang,setSelectedLang] = useState(null);
  const [searchValue,setSearchValue] = useState('');
  const [isLanguageOpen, setLanguageOpen] =useState(false);
  const [isFilterOpen, setIsFilterOpen] =useState(false);
  const [isDateOpen, setIsDateOpen] =useState(false);
  const [isWordOpen, setIsKeyword] =useState(false);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedWords, setSelectedKeywords] = useState([]);
  const [selectedKeyId, setSelectedKeyId] = useState([]);
  const [sortType, setSortType] = useState('Most Relevant');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [isSortTypeOpen, setIsSortTypeOpen] = useState(false);

  const handleSortTypeChange = (type) => {
    setSortType(type);
    
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    setIsSortTypeOpen(false);
    // onSortChange(sortType,order)
    onSortChange('year',order)
  };

  function handleLanguage (value){
    
    setSelectedLang(value);
    onLanguageChange(value);
    setLanguageOpen(false);
    setIsDateOpen(false);
    setIsKeyword(false);
    setIsFilterOpen(false)
  }

  const handleKeywordSelect = (word) => {
    if (selectedWords.includes(word.name)) {
        setSelectedKeywords(selectedWords.filter((item) => item !== word.name));
        
       
    } else {
        setSelectedKeywords([...selectedWords, word.name]);
        
        
    }
    
    if (selectedKeyId.includes(word.id)) {
       
        setSelectedKeyId(selectedKeyId.filter((item) => item !== word.id));
       
    } else {
      
        setSelectedKeyId([...selectedKeyId, word.id]);
        
    }
   
  };

  const applyTags=()=>{
   
    onTagsChange(selectedKeyId)
    setLanguageOpen(false);
    setIsDateOpen(false);
    setIsKeyword(false);
    setIsFilterOpen(false)
  }

  const applyDate =()=>{
     
    onDateChange(selectedStartDate, selectedEndDate)
    setLanguageOpen(false);
    setIsDateOpen(false);
    setIsKeyword(false);
    setIsFilterOpen(false)
  }

  const removeLanguageFilter =()=>{
    setSelectedLang(null)
    onLangRemove()
  }

  const removeDateFilter =()=>{
    setSelectedStartDate(null);
    setSelectedEndDate(null)
    onDateRemove()
  }
    
  const removeIndustryFilter =()=>{
    setSelectedKeywords([]);
    onTagsRemove()
  }


  const toggleFilterOpen =()=>{
    setIsFilterOpen(!isFilterOpen);
    setIsSortTypeOpen(false)
    setLanguageOpen(false);
    setIsDateOpen(false);
    setIsKeyword(false);
  }

const toggleIsSortTypeOpen=()=>{
    setIsSortTypeOpen(!isSortTypeOpen)
    setIsFilterOpen(false)
}
  const toggleLanguageOpen =()=>{
    setLanguageOpen(!isLanguageOpen);
    setIsDateOpen(false);
    setIsKeyword(false);
  }

  const toggleDateOpen =()=>{
    setIsDateOpen(!isDateOpen)
    setLanguageOpen(false);
    setIsKeyword(false);
  }

  const toggleKeywordOpen =()=>{
    setIsKeyword(!isWordOpen)
    setLanguageOpen(false);
    setIsDateOpen(false);
    
  }

  const toggleEndCalendar =()=>{
    setShowEndCalendar(!showEndCalendar)
    setShowStartCalendar(false)
  }
  const toggleStartCalendar =()=>{
    setShowStartCalendar(!showStartCalendar)
    setShowEndCalendar(false)
  }


  const handleDateSelect = (date) => {

    const day = date.getDate();
    const month = date.getMonth() + 1; // Get the month (Note: January is 0)
    const year = date.getFullYear();

    // Format day and month with leading zeros if needed
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    setSelectedStartDate(formattedDate);

    setShowStartCalendar(false)
};

const handleDateSelectEnd = (date) => {

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();


    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    setSelectedEndDate(formattedDate);
    setShowEndCalendar(false)
};


const getIndustry=()=>{
 
    try {
      
      const config = {
        headers: {
          "Content-Type": "application/json",
           'Authorization': `Bearer ${token}`
        },
      };

      axios
        .get(`${baseUrl}industry`,config)
        .then((res) => {
       
            setIndustries(res.data.data)
        
        })
        .catch((error) => {
          
         
            if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
            } else if (error.request) {
                alert("Check your connection. Unable to reach the server.");
            } else {
                alert("An error occurred. Please try again later.");
            }
        });
    }
    catch (error) {
      console.log("error", error);
    }
}

const handleSearch=(value)=>{
    if(!value){
        alert("enter a value")
    }
    else{
        onSearchChange(value)
    }
    
}

const isFiltersActive = 
    (selectedLang !== null) || 
    (selectedStartDate !== null) || 
    (selectedEndDate  !== null) || 
    (selectedWords.length > 0);


    useEffect(()=>{
        
        getIndustry();
    // eslint-disable-next-line
    },[]);

  return (

    <div className='contb-header-cont'>

        <div className='contb-header-box'>

            <h4 className='contb-header'> 
                All Contributions
            </h4>


            <div>
                <div 
                    className='contb-header-search'
                >
                    <IoSearch
                    color='#77787C'
                    onClick={()=> handleSearch(searchValue)}
                    />
                    <input 
                    placeholder='Search'
                    onChange={(e)=>setSearchValue(e.target.value)}
                  
                    type="text" />
                </div>

                <button 
                    onClick={()=>setOpen(true)}
                    className='new-contrbtn'>
                    <FaPlus
                    color='#FFFFFF'
                    size={15}
                    />
                <p> New Contribution</p>
           
                </button>
            </div>

        </div>


        <div className='contb-type-box'>
            <div className='contb-type'>
                <h4
                  onClick={()=>setSelectedItem("data")}
                  className={selectedItem === "data" ? "contb-type-active":"contb-type-inactive"}
                >Data</h4>
                <h4
                  onClick={()=>setSelectedItem("doc")}
                  className={selectedItem === "doc" ? "contb-type-active":"contb-type-inactive"}
                >Documents</h4>
                <h4 
                onClick={()=>setSelectedItem("map")}
                className={selectedItem === "map" ? "contb-type-active":"contb-type-inactive"}
                >
                Maps and Dashboards
                </h4>
            </div>

            <div style={{display:'flex',gap:'16px'}}>
            <div className='sort_dropdown'>
                
                <div 
                    className='sort_dropdown_head'
                    onClick={() => toggleIsSortTypeOpen()} >
                    <LuArrowUpDown/>
                    <h4>{ sortType }</h4>

                    {isSortTypeOpen ? <GoChevronUp /> : <GoChevronDown />}

                </div>
         
           {isSortTypeOpen && (
               <div className='sort_dropdown_cont'>
                   
                   <div style={{}}onClick={() => handleSortTypeChange('Most Relevant')}>
                       <h4>Most Relevant</h4>
                       {sortType === 'Most Relevant' && <MdCheckCircle color='#F9A410' />}
                    </div>

                   <div onClick={() => handleSortTypeChange('date')}>
                       <h4>Date Created</h4>
                       {sortType === 'date' && <MdCheckCircle color='#F9A410' />}
                   </div>

                   <div onClick={() => handleSortTypeChange('Last Modified')}>
                       <h4>Last Modified</h4>
                       {sortType === 'Last Modified' && <MdCheckCircle color='#F9A410' />}
                   </div>

                   <div onClick={() => handleSortTypeChange('views')}>
                       <h4>Views</h4>
                       {sortType === 'views' && <MdCheckCircle color='#F9A410' />}
                   </div>

                   <hr style={{backgroundColor:'#F3F2EF',height:'0.5px',border:'0'}} />

                   <div onClick={() => handleSortOrderChange('ASC')}>
                       <h4>Sort Ascending</h4>
                       {sortOrder === 'ASC' && <MdCheckCircle color='#F9A410' />}
                   </div>
                   <div onClick={() => handleSortOrderChange('DESC')}>
                       <h4>Sort Descending</h4>
                       {sortOrder === 'DESC' && <MdCheckCircle color='#F9A410' />}
                   </div>
                   
               </div>
           )}
           
        </div>

        <div className='news_filter_cont'/>
            <div 
            onClick={()=>toggleFilterOpen()}
            className={ isFiltersActive ? 'news_header_filter_box active' :'news_header_filter_box'}
            >
                <BsFilter />

                <h4> Filters </h4>

                <GoChevronDown />
            </div>


           {isFilterOpen && 
               
                   <div className='news_filter_drop'>
                       <div onClick={()=>toggleLanguageOpen()}>
                           <h4>Language</h4>
                           <IoIosArrowForward 
                           color='#9CA0A5'
                           />
                       </div>
                       <div onClick={()=>toggleDateOpen()}>
                           <h4>Date Range</h4>
                           <IoIosArrowForward
                           color='#9CA0A5'  />
                       </div>
                       <div  onClick={()=>toggleKeywordOpen()}>
                           <h4>Keywords</h4>
                           <IoIosArrowForward 
                           color='#9CA0A5' />
                       </div>
                       
                   </div>
                    }
                   {isLanguageOpen && 
                   <div className='news_filter_lang'>
                       <div onClick={()=>handleLanguage("English")}>
                           {selectedLang ==="English" ? 
                           <IoMdRadioButtonOn 
                           color='#007EFF'
                           />
                           :
                           <IoIosRadioButtonOff
                       
                           color='#DDD8D0'
                           /> }
                           <h4>English</h4>
                       </div>

                       <div onClick={()=>handleLanguage("French")}>
                       {selectedLang ==="French" ? 
                           <IoMdRadioButtonOn 
                           color='#007EFF'
                           />
                           :
                           <IoIosRadioButtonOff
                       
                           color='#DDD8D0'
                           /> }
                           <h4>French</h4>
                       </div>

                       <div onClick={()=>handleLanguage("Portuguese")}>
                       {selectedLang ==="Portuguese" ? 
                           <IoMdRadioButtonOn 
                           color='#007EFF'
                           />
                           :
                           <IoIosRadioButtonOff
                       
                           color='#DDD8D0'
                           /> }
                           <h4>Portuguese</h4>
                       </div>

                   </div>}


                   {isDateOpen && 
                   <div className='news_filter_date'>
                       <p>Select Date</p>

                       <div>
                           <div className='news_filter_date_range'>
                               <h4>From</h4>
                               <div onClick={()=>toggleStartCalendar()}>
                                   <p>{selectedStartDate}</p>
                                   <CiCalendar 
                                       size={17}
                                   />
                               </div>
                           
                           </div>
                           {showStartCalendar && 
                           
                           <Calendar 
                           onDateSelect={handleDateSelect}
                           top={"120px"}/>
                           
                           }
                           

                       </div>
                   

                   
                       <div>
                           <div className='news_filter_date_range'>
                               <h4>To</h4>
                               <div onClick={()=>toggleEndCalendar()}>
                                   <p>{selectedEndDate}</p>
                                   <CiCalendar 
                                       size={17}
                                   />
                               </div>
                           </div>
                           {showEndCalendar && 
                           <Calendar
                           onDateSelect={handleDateSelectEnd}
                       
                           top = {"209px"} />}
                           

                       </div>
                   
                       <div  className='news_filter_btn'>
                           <button
                           onClick={()=>applyDate()}
                           disabled={(!selectedStartDate && !selectedEndDate) || (new Date(selectedStartDate) > new Date(selectedEndDate))}
                           >Apply</button>
                       </div>
                       
                   </div>
                   }

                   {isWordOpen && 
                   <div className='news_filter_keywd_main'>
                       <div className='news_filter_keywd'>
                           {industries.map((item,index)=>(
                               <div className='news_filter_keywd_grid' key={index}>
                                   <div>
                                       {selectedWords.includes(item.name) ? (
                                           
                                           <img 
                                           onClick={()=>handleKeywordSelect(item)}
                                           src={checkedIcon} alt="check" />
                                       ):(
                                           <img 
                                           onClick={()=>handleKeywordSelect(item)}
                                           src={checkIcon} alt="check" />
                                       )}
                                       

                                   </div>
                                   <h4>{item.name}</h4>
                               </div>                            
                           
                           ))}

                           
                       
                       </div>
                           <div style={{marginTop:'0px'}} className='news_filter_btn'>
                               <button 
                               disabled={selectedWords.length === 0}
                               onClick={()=>applyTags()}>
                                   Apply
                               </button>
                           </div>
                          
                       </div>
                                        }
                        </div>
                                    
                        </div>

                        <div className='filter_items'>
                        {selectedLang &&
                        <div>
                                <IoLanguage
                                color='#007EFF'
                                />
                                <p>{selectedLang} </p>
                                <IoMdClose
                                color='#007EFF'
                                size={18}
                                onClick={()=>removeLanguageFilter()}
                                />
                        </div>
                        }
                        {selectedWords.length > 0 &&
                        <div>
                            <FaHashtag
                            
                            color='#007EFF'
                            />
                            <p> {selectedWords.slice(0,3) + (selectedWords.length > 3 ? "   (+" + (selectedWords.length - 3) + ")" : "")}</p>  
                            <IoMdClose
                            color='#007EFF'
                            size={18}
                            onClick={()=>removeIndustryFilter()}
                            />           
                        </div>}

                        {(selectedEndDate && selectedStartDate) &&
                        <div>
                            <CiCalendar
                                color='#007EFF'
                            />
                            <p>{selectedStartDate} - {selectedEndDate}</p>
                            
                            <IoMdClose
                                color='#007EFF'
                                onClick={()=>removeDateFilter()}
                            />
                        </div>}
                        
                        </div>
        
        

        <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} overflow-x >

                <div style={{textAlign:"right"}}>
                    <img 
                    onClick={()=>setOpen(false)}
                    src={closeIcon} alt="" />
                </div>
                

                <div className='cont_modal_header'>

                    <h2>Add New Contribution</h2>
                    

                </div>

                <h3 className='cont_modal_sub'> What type of resource are you adding? </h3>

                <div className='cont_modal_content'>

                    <div>
                        <div>
                         { selectedItem === "data" ? 
                            <div>
                                <img src={checked} alt="" />
                            </div>
                             
                            :
                            <div>
                                 <img 
                                onClick={()=>setSelectedItem("data")}
                                src={check} alt="icon" 
                                />
                            </div>
                           
                         }
                        </div>
                        

                        <div>
                            <h4> Data</h4>
                            <p>Add a new table by importing CSV or creating manually.</p>
                        </div>
                    </div>
                    

                    <img
                    style={selectedItem === "data" ? {border:'1px solid blue',borderRadius :"4px"} :{} }
                    src={paper1} alt="paperimg" />
                </div>


                <div className='cont_modal_content'>

                    <div>

                        { selectedItem === "doc" ? 
                            <div>
                              <img src={checked} alt="" />
                           </div>
                          :
                          <div>
                              <img 
                                onClick={()=>setSelectedItem("doc")}
                                src={check} alt="icon" 
                                />
                          </div>
                         }
                        

                        <div>
                            <h4>Documents</h4>
                            <p> Attach a pdf document</p>
                        </div>
                    </div>

                   

                    <img  
                    style={selectedItem === "doc" ?  {border:'1px solid blue',borderRadius :"4px"}  :{} }
                    src={paper2} alt="paperimg" />
                </div>

                
                <div className='cont_modal_content'>

                    <div>
                        { selectedItem === "table" ? 
                             <div>
                                <img src={checked} alt="" />
                             </div>
                            :
                            <div>
                                <img 
                            onClick={()=>setSelectedItem("table")}
                            src={check} alt="icon" 
                            />
                            </div>
                         }

                        <div>
                            <h4>Infographics</h4>
                            <p>Embed the link to an interactive map or dashboard</p>
                        </div>
                    </div>

                   

                    <img
                    style={selectedItem === "table" ?  {border:'1px solid blue',borderRadius :"4px"} :{} }
                    src={paper1} alt="paperimg" />
                </div>

                <button 
                onClick={()=>  window.location.href="/admin/createdoc"}
                className='cont_modal_button'>
                    Proceed
                </button>
              </Box>
        </Modal>
      
    </div>
  )
}

export default ContributionHeader
