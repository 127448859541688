import React from 'react'
import './AdminResourceTable.scss';
import doc from '../../../../assets/reportIcon.svg'
import { HiOutlineUser } from "react-icons/hi2";
import { BsDownload } from "react-icons/bs";
import { VscEye } from "react-icons/vsc";
import { IoIosArrowForward } from "react-icons/io";


const AdminResourceTable = ({tableData}) => {


    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options); 
      }
    
    
      return (

        <div className='admin-resc-table-cont'>

        
            <table  style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead className='admin-resc-head'>
                <tr>
                <th style={{paddingLeft:'12px'}}>Title</th>
                <th>Contributor</th>
                <th>Downloads</th>
                <th>Views</th>
                <th>Last Updated</th>
                <th>Status</th>
                <th></th>
                </tr>
            </thead>

            <tbody className='admin-resc-body' >
                {tableData.map((row, index) => (
                <tr key={index}>

                    <td >
                        <div className='admin-col-one'>
                            <img src={doc} alt="" />
                            <p
                            onClick={()=>window.location.href=`/view/report/pdf/${row.id}`}
                            >{row.title.slice(0,20)}{row.title.length > 20 ? "..." : ""}</p>
                        </div>
                        
                    </td>

                    <td >
                        <div className='admin-col-two'>
                            <HiOutlineUser/>
                            <p>{row.created_by_first_name + " " + row.created_by_last_name }</p>
                        </div>
                    
                    </td>

                    <td >
                        <div className='admin-col-two'>
                            <BsDownload/>
                            <p>{row.total_downloads}</p>
                        </div>
                        
                    </td>

                    <td >
                        <div className='admin-col-two'>
                            <VscEye/>
                            <p>{row.total_views}</p>
                        </div>
                        
                    
                    </td>

                    <td >
                        <div className='admin-col-two'>
                        <p>{formatDate(row.updated_at)}</p>
                        </div>
                        
                    </td>

                    <td 
                    
                    >
                    <p
                        className='admin-col-status'
                        style={{
                        color:
                            row.status === "rejected"
                            ? "#9E0A05"
                            : row.status === "pending"
                            ? "#8B5904"
                            : row.status === "withdrawn"
                            ? "#4B4F57"
                            :
                             row.status === "suspended"
                            ? "#9E0A05"
                            : "#036B26",

                         backgroundColor:
                         row.status === "rejected"
                         ? "#FBEAE9"
                         : row.status === "pending"
                         ? "#FEF0D8"
                         : row.status === "withdrawn"
                         ? "#F3F2EF"
                         :
                         row.status === "suspended"
                         ? "#FBEAE9"
                         
                         : "#E7F6EC",

                       

                        }}
                    >
                        {row.status}</p>
                    
                    </td>
                    <td>
                        <IoIosArrowForward
                         color='#9CA0A5'
                         size={20}
                        />
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>
      );
}

export default AdminResourceTable
